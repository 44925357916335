body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Legend M54";
  src: local("Legend M54"),
    url("https://web.amma.social/fonts/legend_m54/Legend/M54.ttf") format("truetype");
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */




input[type=range].styled-slider {
  height: 15px;
  -webkit-appearance: none;
}

/*progress support*/
input[type=range].styled-slider.slider-progress {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 15px + var(--ratio) * (100% - 15px));
}

input[type=range].styled-slider:focus {
  outline: none;
}

/*webkit*/
input[type=range].styled-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 1em;
  background: #FF0000;
  border: none;
  box-shadow: 0 0 2px black;
  margin-top: calc(max((8px - 1px - 1px) * 0.5,0px) - 15px * 0.5);
  cursor: pointer;
}

input[type=range].styled-slider::-webkit-slider-runnable-track {
  height: 8px;
  border: 1px solid #b2b2b2;
  border-radius: 0.5em;
  background: #efefef;
  box-shadow: none;
}

input[type=range].styled-slider::-webkit-slider-thumb:hover {
  background: #FF0000;
}

input[type=range].styled-slider:hover::-webkit-slider-runnable-track {
  background: #e5e5e5;
  border-color: #9a9a9a;
}

input[type=range].styled-slider::-webkit-slider-thumb:active {
  background: #FF0000;
}

input[type=range].styled-slider:active::-webkit-slider-runnable-track {
  background: #f5f5f5;
  border-color: #c1c1c1;
}

input[type=range].styled-slider.slider-progress::-webkit-slider-runnable-track {
  background: linear-gradient(#F80000,#F80000) 0/var(--sx) 100% no-repeat, #efefef;
}

input[type=range].styled-slider.slider-progress:hover::-webkit-slider-runnable-track {
  background: linear-gradient(#F80000,#F80000) 0/var(--sx) 100% no-repeat, #e5e5e5;
}

input[type=range].styled-slider.slider-progress:active::-webkit-slider-runnable-track {
  background: linear-gradient(#F80000,#F80000) 0/var(--sx) 100% no-repeat, #f5f5f5;
}

/*mozilla*/
input[type=range].styled-slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 1em;
  background: #FF0000;
  border: none;
  box-shadow: 0 0 2px black;
  cursor: pointer;
}

input[type=range].styled-slider::-moz-range-track {
  height: max(calc(8px - 1px - 1px),0px);
  border: 1px solid #b2b2b2;
  border-radius: 0.5em;
  background: #efefef;
  box-shadow: none;
}

input[type=range].styled-slider::-moz-range-thumb:hover {
  background: #FF0000;
}

input[type=range].styled-slider:hover::-moz-range-track {
  background: #e5e5e5;
  border-color: #9a9a9a;
}

input[type=range].styled-slider::-moz-range-thumb:active {
  background: #FF0000;
}

input[type=range].styled-slider:active::-moz-range-track {
  background: #f5f5f5;
  border-color: #c1c1c1;
}

input[type=range].styled-slider.slider-progress::-moz-range-track {
  background: linear-gradient(#F80000,#F80000) 0/var(--sx) 100% no-repeat, #efefef;
}

input[type=range].styled-slider.slider-progress:hover::-moz-range-track {
  background: linear-gradient(#F80000,#F80000) 0/var(--sx) 100% no-repeat, #e5e5e5;
}

input[type=range].styled-slider.slider-progress:active::-moz-range-track {
  background: linear-gradient(#F80000,#F80000) 0/var(--sx) 100% no-repeat, #f5f5f5;
}

/*ms*/
input[type=range].styled-slider::-ms-fill-upper {
  background: transparent;
  border-color: transparent;
}

input[type=range].styled-slider::-ms-fill-lower {
  background: transparent;
  border-color: transparent;
}

input[type=range].styled-slider::-ms-thumb {
  width: 15px;
  height: 15px;
  border-radius: 1em;
  background: #FF0000;
  border: none;
  box-shadow: 0 0 2px black;
  margin-top: 0;
  box-sizing: border-box;
  cursor: pointer;
}

input[type=range].styled-slider::-ms-track {
  height: 8px;
  border-radius: 0.5em;
  background: #efefef;
  border: 1px solid #b2b2b2;
  box-shadow: none;
  box-sizing: border-box;
}

input[type=range].styled-slider::-ms-thumb:hover {
  background: #FF0000;
}

input[type=range].styled-slider:hover::-ms-track {
  background: #e5e5e5;
  border-color: #9a9a9a;
}

input[type=range].styled-slider::-ms-thumb:active {
  background: #FF0000;
}

input[type=range].styled-slider:active::-ms-track {
  background: #f5f5f5;
  border-color: #c1c1c1;
}

input[type=range].styled-slider.slider-progress::-ms-fill-lower {
  height: max(calc(8px - 1px - 1px),0px);
  border-radius: 0.5em 0 0 0.5em;
  margin: -1px 0 -1px -1px;
  background: #F80000;
  border: 1px solid #b2b2b2;
  border-right-width: 0;
}

input[type=range].styled-slider.slider-progress:hover::-ms-fill-lower {
  background: #F80000;
  border-color: #9a9a9a;
}

input[type=range].styled-slider.slider-progress:active::-ms-fill-lower {
  background: #F80000;
  border-color: #c1c1c1;
}


@media (max-width: 768px){
  #thumbnails {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 60px;
    background-color: #c5c5c547;
    transition: 0.3s ease-in-out all;
  }
  .showThumbnail{  
    -webkit-transform: translateY(80px);  
            transform: translateY(80px)
  }
  .hideThumbnail{  
    -webkit-transform: translateY(0);  
            transform: translateY(0)
  }
}

#thumbnails ul{
  margin: 0
}
html, body{
  height: 100%;
  min-height: 100%;
}
button{
  white-space: nowrap;
}
#root{height: 100%}
.App {
  text-align: center;
  overflow: auto;
  height: 100%;
}
::-webkit-scrollbar {   
  z-index: 999999;
  width: 8px;
  height: 8px;
}
/*  */
::-webkit-scrollbar-track {
  background: #FF305340;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #FF3053;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555,
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
/* .playerContainer > div > div{
  border: 1px solid #ccc
} */
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

*{
  word-break: break-word;
}

img.square_img{
  width: 60px !important;
  height: 60px !important;
  aspect-ratio: 1/1 !important
}
.templateContainer {
  /* text-align: center; */
  margin: 0 auto;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  max-width: 700px !important;
  position: relative 
}
@media screen and (min-width: 1500px) and  (max-width: 2500px){
  .templateContainer{
    max-width: 900px !important;
  }

}
@media screen and (max-width: 600px){
  .templateContainer{
    max-width: 320px !important;
  }
}
.templatesCount{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.templatesCount h3{
  font-size: 18px;
  font-weight: 500;
  margin: 0px 0 10px;
}
